
import { defineComponent, onMounted, reactive } from 'vue';
import { Badge } from 'ant-design-vue';
import {
  ColumnHeightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { queryUrlNotBind } from '@/api/permission/apiurl-manage.ts';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import { useStore } from 'vuex';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import { useForm } from 'ant-design-vue/es/form';
import { useI18n } from 'vue-i18n';

const statusMap = {
  true: { text: '正常', status: 'green' },
  false: { text: '作废', status: 'red' },
};
const baseColumns: TableColumn[] = [
  {
    title: '序号',
    width: 100,
    dataIndex: 'index',
    slots: { customRender: 'index' },
  },
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: 'url',
    dataIndex: 'url',
  },
  {
    title: '是否需要授权',
    width: 130,
    dataIndex: 'is_auth',
    slots: { customRender: 'auth' },
  },
  {
    title: '状态',
    width: 130,
    dataIndex: 'is_active',
    slots: { customRender: 'status' },
    filters: [
      {
        text: status[0],
        value: true,
      },
      {
        text: status[1],
        value: false,
      },
    ],
  },
];

export default defineComponent({
  name: 'ApiurlManage-2',
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const tableHeight = reactive({ y: window.innerHeight - 130 });
    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 130;
      };
    });
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, true);
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();

    const modelRef = reactive({
      org_id: current_org.id,
      is_active: true,
      name: '',
      url: '',
    });
    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryUrlNotBind, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...modelRef,
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current,
        pageSize,
        ...filters,
        ...modelRef,
      });

      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...modelRef,
      });
      reload();
    };

    const config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '接口名称',
          label_i18n: '接口名称',
          placeholder_i18n: '请填写接口名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写接口名称',
        },
        {
          type: 'select',
          name: 'is_auth',
          label: '是否需要授权',
          label_i18n: '是否需要授权',
          placeholder_i18n: '请选择是否授权',
          disabled: false,
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择是否授权',
          datasource: 'is_auth',
          mode: 'default',
        },
        {
          type: 'select',
          name: 'is_active',
          label: '状态',
          label_i18n: '状态',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择状态',
          datasource: 'is_active',
          mode: 'default',
        },
      ],
      rules: {},
      model: modelRef,
    };
    const { validateInfos } = useForm(modelRef, {});
    const config_options = {
      is_active: [
        { name: '全部', value: '' },
        { name: '正常', value: true },
        { name: '禁用', value: false },
      ],
      is_auth: [
        { name: '全部', value: '' },
        { name: t('是'), value: true },
        { name: t('否'), value: false },
      ],
    };

    return {
      statusMap,
      tableHeight,
      state,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      modelRef,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,

      config,
      config_options,
      validateInfos,
    };
  },
  components: {
    DragIcon,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    SearchRender,
    [Badge.name]: Badge,
  },
});
